<template>
  <el-row 
    type="flex" 
    class="like-dislike-row" 
    align="middle"
  >
    <div 
      v-loading="isInteracting"
      class="like-section"
      @click="(e) => toggleInteraction(e, 'LIKE')"
    >
      <i 
        class="custom-icon mini"
        :class="isLiked ? 'icon-liked-bold' : 'icon-like'"
      /> 
      {{ getInteractionCount("LIKE") }}
    </div>
    <div 
      v-loading="isInteracting"
      class="dislike-section"
      @click="(e) => toggleInteraction(e, 'DISLIKE')"
    >
      <i 
        class="custom-icon mini"
        :class="isDisliked ? 'icon-disliked-bold' : 'icon-dislike'"
      /> 
      {{ getInteractionCount("DISLIKE") }}
    </div>
  </el-row>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { createArticleInteraction } from "@/api/article";

export default {
  name: "ArticleLikeDislikeSection",
  mixins: [ generalMixin ],
  props: {
    article: { type: Object, required: true },
    isPreview: { type: Boolean, default: false },
  },
  data() {
    return {
      isInteracting: false,
    }
  },
  computed: {
    isLiked() {
      return this.article.interaction?.isUserLiked === true
    },
    isDisliked() {
      return this.article.interaction?.isUserDisliked === true
    }
  },
  methods: {
    toggleInteraction(e, actionType) {
      e.stopPropagation()
      if (this.isPreview) return // no action

      if (this.isAuthenticated == false) {
        this.goTo('/auth/sign-in')
        return
      }

      let payload = { actionType }

      if (
        actionType === "LIKE" && this.isLiked
        || actionType === "DISLIKE" && this.isDisliked
      ) {
        payload.unassignArticleIdList = [this.article.id]
        
      } else {
        payload.assignArticleIdList = [this.article.id]
      }

      this.isInteracting = true
      createArticleInteraction(payload).then((res) => {
        if (res.status !== "ok") return
        this.$emit("onInteractionChange", res.result.interaction)
        
      }).finally(() => {
        this.isInteracting = false
      })

    },
    getInteractionCount(type) {
      if (this.isPreview) {
        return Math.floor(Math.random() * 100)
      }

      if (type === "LIKE") {
        return this.article.interaction?.likeCount || 0
        
      } else {
        return this.article.interaction?.dislikeCount || 0
      }
    }
  }
}
</script>

<style lang="scss">

@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";

.like-dislike-row {
  margin-left: auto;

  .like-section,
  .dislike-section {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 3px 6px;
    border-radius: 5px;

    &:hover {
      background: #F0F0F0;
    }
  }

  .dislike-section {
    margin-left: 3px;
  }

  .icon-like,
  .icon-dislike,
  .icon-liked-bold,
  .icon-disliked-bold {
    margin-right: 2px;
  }

  .icon-dislike,
  .icon-disliked-bold {
    margin-top: 2px; /* push down a bit */
  }
}

</style>