<template>
  <div id="public-complain">
    <h1 class="header">{{ $t("MAKE_YOUR_COMPLAINT") }}</h1>

    <el-form 
      v-loading="isLoading"
      ref="form" 
      :model="form" 
      :rules="rules" 
      label-position="top"
      @submit.native.prevent="handleSubmit"
    >
      <el-collapse-transition>
        <div class="intro">
          <h1>{{ $t("COMPLAINT_INTRO_1") }}</h1>
          <br v-if="!isMobile" />
          <p>{{ $t("COMPLAINT_INTRO_2") }}</p>
          <br />
          <br />
          <h1>{{ $t("COMPLAINT_INTRO_3") }}</h1>
          <p>{{ $t("COMPLAINT_INTRO_4") }}</p>
          <br />
          <ul>
            <li>{{ $t("YOUR_FULL_NAME") }}</li>
            <li>{{ $t("YOUR_EMAIL") }}</li>
            <li>{{ $t("YOUR_CONTACT") }}</li>
          </ul>
        </div>
      </el-collapse-transition>

      <!-- Dynamic detail, will show depends on type of complain -->
      <h4>{{ $t("PREVIEW") }}</h4>
      <div class="preview-wrapper" :class="type">
        <div v-if="type === 'article'">
          <article-summary
            orientation="portrait"
            size="small"
            withImage
            withHighlight
            withWhiteBg
            hideDescription
            hideTime hideCategory
            :articleObj="detail"
          />
        </div>
        <div v-else-if="type === 'advertisement'">
          <el-image
            v-if="adsImageUrl"
            :src="adsImageUrl"
            :preview-src-list="[adsImageUrl]"
            class="ads-image"
            fit="scale-down"
            alt="complain advertisement image"
          />
        </div>
        <div v-else-if="type === 'p2p_transaction'">
          <h4>{{ $t("PURCHASE_DETAIL") }}</h4>
          <el-divider/>
          <el-row :type="isMobile ? '':'flex'" justify="space-between" align="middle" :gutter="40">
            <el-col>
              <div class="label">{{ $t("NO_OF_COIN") }}:</div>
              <b class="text-green">{{ detail.coin }}</b>
            </el-col>
            <el-col>
              <div class="label">{{ $t("TRANSACTED_AMOUNT") }}:</div>
              <b class="text-green">{{ p2pTransactionAmount }}</b>
            </el-col>
            <el-col>
              <div class="label">{{ $t("TRANSACTION_REFERENCE_ID") }}:</div>
              {{ detail.transactionRef }}
            </el-col>
            <el-col>
              <!-- Leave blank purposely -->
            </el-col>
          </el-row>
          <el-divider/>
          <bank-details 
            v-if="bankDetailsDisplayFor"
            :detail="detail"
            :displayFor="bankDetailsDisplayFor"
            :label="bankDetailsLabel"
            labelFillColor="#A44823"
            labelTextColor="white"
            labelBorderColor="#A44823"
          />
        </div>
      </div>

      <el-form-item :label="$t('COMPLAINT_TYPE_PUBLIC')" class="is-required">
        <el-select
          v-if="type"
          class="w-100"
          disabled
          :value="$t(type.toUpperCase())"
        />
      </el-form-item>
      <el-form-item :label="$t('COMPLAINT_TITLE_PUBLIC')" prop="subject" class="is-required">
        <el-input 
          v-model="form.subject" 
          :placeholder="$t('COMPLAINT_TITLE_PLACEHOLDER')"
          type="textarea" 
          resize="none" 
          :rows="1"
          maxlength="50" 
          show-word-limit
        />
      </el-form-item>
      <el-form-item :label="$t('COMPLAINT_CONTENT')" prop="content" class="is-required">
        <el-input 
          v-model="form.content" 
          :placeholder="$t('COMPLAINT_CONTENT_PLACEHOLDER')"
          type="textarea" 
          resize="none" 
          :rows="4"
          maxlength="850" 
          show-word-limit
        />
      </el-form-item>

      <h1>{{ $t("YOUR_DETAILS") }}</h1>
      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('FIRST_NAME')" class="is-required w-100">
            <el-input :value="user.firstName" disabled />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('EMAIL')" class="is-required w-100">
            <el-input :value="user.email" disabled />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('PHONE')" class="is-required w-100">
            <el-input :value="user.phone" disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <page-footer
        :isLoading="isLoading"
        :handleBackTo="exit"
        :handleSubmit="() => {}"
        :submitBtnText="$t('SUBMIT')"
      />
    </el-form>
  </div>
</template>

<script>
import i18n from '@/locales'
import cloneDeep from "lodash/cloneDeep"
import { mapGetters } from "vuex"
import ArticleSummary from "./components/article-summary.vue"
import BankDetails from "@/views/bo/p2p-market/components/bank-details.vue"
import PageFooter from "@/components/page-footer.vue"
import { generalMixin } from "@/utils/general-mixin.js"
import { getComplainTypeList } from "@/api/utility.js"
import { createComplain } from "@/api/complain.js"
import { getAdsbyId } from "@/api/advertisement.js"
import { getArticleById } from "@/api/article.js"
import { getTransactionByRefId } from "@/api/p2p-market"

const formRules = {
  subject: [
    {
      required: true,
      message: i18n.t("COMPLAINT_TITLE_REQUIRED"),
      trigger: "blur",
    },
  ],
  content: [
    {
      required: true,
      message: i18n.t("COMPLAINT_CONTENT_REQUIRED"),
      trigger: "blur",
    },
  ],
};

const emptyPhotoUrlForm = require('@/assets/pngs/no-image-placeholder.png')

export default {
  name: "PublicComplain",
  components: {
    PageFooter, ArticleSummary, BankDetails
  },
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      id: null,
      type: null,
      detail: {},
      rules: cloneDeep(formRules),
      form: {
        subject: null,
        content: null,
      },
      typeList: [],
      bankDetailsDisplayFor: null,
      bankDetailsLabel: ""
    }
  },
  computed: {
    ...mapGetters(["user", "locale"]),
    adsImageUrl() {
      if (this.detail.fileUploadList == null || this.detail.fileUploadList.length === 0) {
        return emptyPhotoUrlForm
      }
      let found = this.detail.fileUploadList.find(el => el.language.code == this.locale)
      if (!found) { // fallback to en
        found = this.detail.fileUploadList.find(el => el.language.code == "en")
      }
      if (!found) { // fallback to first element
        found = this.detail.fileUploadList[0]
      }
      return found.upload.url
    },
    p2pTransactionAmount() {
      return `${this.detail.post?.currency?.code?.toUpperCase()} ${this.detail.price*this.detail.coin}`
    },
  },
  methods: {
    exit() {
      if (this.type === "article") {
        this.goToArticle(this.detail)
      } else if (this.type === "advertisement") {        
        this.goToArticle(this.detail.article)
      } else if (this.type === "p2p_transaction") {
        this.goTo(`/bo/p2p-market/transaction/${this.detail.transactionRef}`)
      }
    },
    getComplainTypeList() {
      getComplainTypeList().then(res => {
        if (res.status == "ok") {
          this.typeList = res.result.list
        }
      })
    },
    handleSubmit() {
      switch(this.type) {
        case "article": {
          this.form.article = { id: this.id }
          break
        }
        case "advertisement": {
          this.form.advertisement = { id: this.id }
          break
        }
        case "p2p_transaction": {
          this.form.p2pTransaction = { id: this.detail.id }
          break
        }
      }
      
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          this.$notify({
            type: 'error',
            message: this.$t("INVALID_FORM_MESSAGE"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION 
          })
          return
        }
        this.isLoading = true
        createComplain(this.form).then(res => {
          if (res.status == 'ok') {
            this.$notify({
              type: 'success',
              message: this.$t("COMPLAINED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            })
            this.exit()
          }
        })
        .finally(() => this.isLoading = false)
      });
    },
    kickOutGuest() {
      this.$notify({
        type: "error",
        message: this.$t("LOG_IN_TO_COMPLAIN"),
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      })
      this.exit()
    },
    getDetail() {
      switch(this.type) {
        case "article": {
          this.isLoading = true
          getArticleById(this.id).then(res => {
            if (res.status != "ok") return
            this.detail = cloneDeep(res.result)
          })
          .finally(() => this.isLoading = false)
          break
        }
        case "advertisement": {
          this.isLoading = true
          getAdsbyId(this.id).then(res => {
            if (res.status != "ok") return
            this.detail = cloneDeep(res.result)
          })
          .finally(() => this.isLoading = false)
          break
        }
        case "p2p_transaction": {
          this.isLoading = true
          getTransactionByRefId(this.id).then(res => {
            if (res.status != "ok") return
            this.detail = cloneDeep(res.result)
            if (this.user.id === this.detail.buyer.id) {
              this.bankDetailsDisplayFor = "seller"
              this.bankDetailsLabel = this.$t('SELLER_DETAILS')
            } else {
              this.bankDetailsDisplayFor = "buyer"
              this.bankDetailsLabel = this.$t('BUYER_DETAILS')
            }
          })
          .finally(() => this.isLoading = false)
          break
        }
      }
    },
  },
  watch: {
    isAuthenticated(value) {
      if (!value) this.kickOutGuest()
    }
  },
  mounted() {
    this.id = this.$route.params?.id
    this.type = this.$route.params?.type

    this.getDetail()

    if (this.isAuthenticated == false) {
      this.kickOutGuest()
    }

    this.getComplainTypeList()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/detail.scss";

#public-complain {
  @include detail-form;
  margin-top: 1rem;
  margin-bottom: 5rem;
  .header {
    font-size: 2rem;
  }
  .preview-wrapper {
    margin: 2rem 0;
    padding: 0.5rem;
    border-radius: 10px;
    padding-bottom: 0;
    border: 3px dashed #BCC1DA;
    height: fit-content;
    &.article {
      width: 100%
    }
    &.advertisement {
      width: fit-content;
    }
    &.p2p_transaction {
      padding: 1.25rem;
    }
    .article-summary {
      width: 100%;
    }
    .ads-image {
      max-height: 30vh;
      border-radius: 10px;
    }
  }
  .el-form {
    background-color: $white;
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 5px;
    .intro {
      margin-bottom: 1rem;
      h1 {
        font-size: 25px;
        line-height: 35px;
      }
      p,
      li {
        font-size: 18px;
        line-height: 30px;
        color: #767396;
      }
      li {
        margin-left: 1.5rem;
      }
    }
    .el-form-item__error {
      top: calc(100% - 15px)
    }
  }
  .label {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  textarea {
    font-family: inherit;
  }
}

.mobile #public-complain {
  .header{
    font-size: 1.5rem;
  }
  .el-steps {
    width: 100%;
    .el-step__icon-inner {    
      font-size: 18px;
    }
    .el-step__icon {
      width: 35px;
      height: 35px;
    }
    .el-step__title {
      font-size: 14px;  
    }
  }
  h1 {
    font-size: 18px;
    line-height: 35px;
  }
  .intro {
    p, li{
      font-size: 14px;
      line-height: 20px;
    }
  }
  .preview-wrapper {
    margin: 1rem 0;
  }
  .p2p_transaction {
    .label {
      margin-bottom: 0!important;
      line-height: inherit;
      font-size: 0.8rem;
    }
    .el-col {
      margin-bottom: 0.5rem;
    }
  }
}
</style>